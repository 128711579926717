import { media } from 'helpers/breakpoints';
import styled from 'styled-components';
import { TAutoCompleteSearchRequest } from 'types';

export interface SearchItemProps {
  searchText: string;
  autoSuggestedText: string;
  sectionText?: string;
  sectionTextMuted?: string;
  url: string;
  handleSelect: (
    autoSuggestedText: string,
    url: string,
    searchRequest: TAutoCompleteSearchRequest,
    category?: string,
  ) => void;
  searchRequest: TAutoCompleteSearchRequest;
  isActive?: boolean;
  index: number;
  hoverCallback: (index: number) => void;
  leftIcon?: string;
  isInitialValue?: boolean;
}

const ListItem = styled.li<{ isWordWrap: boolean; isActive?: boolean }>`
  padding: ${({ theme, isWordWrap }) =>
    `${!isWordWrap ? theme.spacing.S12 : theme.spacing.S8} ${
      theme.spacing.M16
    }`};
  cursor: pointer;
  list-style: none;

  &:hover {
    background-color: ${({ theme }) => theme.colors.GREY_LIGHTER};
  }
  ${({ theme, isActive }) =>
    isActive && `background-color: ${theme.colors.GREY_LIGHTER}`};
`;

const LeftText = styled.p<{ isInitialValue: boolean }>`
  display: inline;
  ${({ theme }) => theme.fontSize.B14};
  max-width: 246px;
  word-wrap: break-word;

  ${({ theme, isInitialValue }) =>
    !isInitialValue &&
    `
  & span {
    font-weight: ${theme.fontWeight.bold};
  }
  `}

  ${media.large} {
    ${({ theme }) => theme.fontSize.B16};
    max-width: initial;
  }
`;

const RightText = styled.p<{ isLeftText: boolean; muted?: boolean }>`
  display: inline-block;
  ${({ theme }) => theme.fontSize.B14};
  margin-left: ${({ theme, isLeftText }) =>
    isLeftText ? theme.spacing.S8 : '0'};
  color: ${({ theme, muted }) =>
    muted ? theme.colors.GREY_DARK : theme.colors.BLUE};
  text-transform: capitalize;

  ${media.large} {
    ${({ theme }) => theme.fontSize.B16};
  }
`;

const Icon = styled.img`
  display: inline;
  margin-right: ${({ theme }) => theme.spacing.S8};
`;

function SearchItem({
  searchText,
  autoSuggestedText,
  sectionText,
  sectionTextMuted,
  url,
  handleSelect,
  searchRequest,
  isActive,
  index,
  hoverCallback,
  leftIcon,
  isInitialValue = false,
}: SearchItemProps) {
  return (
    <ListItem
      onClick={() =>
        handleSelect(autoSuggestedText, url, searchRequest, sectionText)
      }
      isWordWrap={Boolean(autoSuggestedText) && autoSuggestedText.length > 35}
      isActive={isActive}
      onMouseOver={() => hoverCallback(index)}
    >
      {leftIcon && <Icon src={leftIcon} />}
      <LeftText isInitialValue={isInitialValue}>
        {sectionText && / Section$/.test(sectionText) ? '' : searchText}
        <span>
          {autoSuggestedText &&
            autoSuggestedText.substr(searchText ? searchText.length : 0)}
        </span>
      </LeftText>
      {sectionText && (
        <RightText isLeftText={Boolean(autoSuggestedText)}>
          {sectionText}
        </RightText>
      )}
      {sectionTextMuted && (
        <RightText muted={true} isLeftText={Boolean(autoSuggestedText)}>
          {sectionTextMuted}
        </RightText>
      )}
    </ListItem>
  );
}

export { SearchItem };
